.customer-box {
    border:1px solid lightgrey;
    min-height: 50px;
    margin:0 5px 5px 0;
    padding:2px;
    border-radius: 6px;
}

.customer-title {
    font-size: 1em;
    color: navy;
    padding-left: 5px;
    font-weight: 500;
    padding-bottom:5px;
}
.customer-content {
    font-size: 0.7em;
    color: black;
    /* border: 0.04em dotted grey; */
    padding: 5px 0px 0px 5px;
    font-weight: 400;
    border-radius: 0px;

}

.customer-actions  {

    font-size: 0.8em;
    font-weight: 400;
    text-decoration: none;
    text-align: right;
    padding-top:5px;
}