
@media all{

    table {
        border-collapse: collapse;
        width: 93%;zoom: 0.75;
    }

    table, td, th {
        border: 0.1em solid black;
        text-align:center;
       
    }
    table td { padding: 3px;}
    .xsCell  {width:50px;}
    .smCell  {width:100px;}
    .mdCell {width:150px;}
    .lgCell {min-width:200px;}
    .td-center {text-align:center;}
    .th-text { font-weight: 600;}
    .text-left { text-align: left; }
}

@media print {
    table { page-break-inside:auto; zoom: 0.65 }
    tr    { page-break-inside:avoid; page-break-after:auto }
}