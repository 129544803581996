body {
    font-size: 0.9em;
}

.hr {
    margin:0;padding:0
}

.title {
    font-size: 1.1em;
    font-weight: 800;
    margin-left: 10px;
    padding-bottom: 10px;
}

.box {
    border: 1px dashed rgb(172, 172, 172);
    height: 160px;
    width: 400px;
    border-radius: 30px;
    padding:10px 10px 10px 10px;
    background: rgb(248, 247, 247);
    line-height: 1.4;
    text-align:left;
}