.btn-default {
    background: white;
    color: rgb(35, 12, 73);
    border: 1px solid black;
    min-width: 170px;
    text-align: center;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 100;
    line-height:2;
}

.btn-default:hover {
    background: rgb(198, 216, 250);
    color: black;
    font-weight: 700;
    
}

button a {
    font-weight: bold;
    line-height:1.5;
    text-decoration:none;
}

.btn-selected {

    background: rgb(34, 6, 196);
    color: white;
    border: 1px solid  rgb(228, 230, 243);
    font-weight: 600;
    min-width: 170px;
    font-weight: 100;
    text-align: center;
    border-radius: 2px;
    font-size: 10px;
    line-height:2;
}

.input {
    height: 30px;
    width: 300px;
    padding-left:10px;
    border: 1px dashed lightgray;
}

.center {
    margin: auto;
    width: 50%;
    text-align:center;
}