
.outlined {
    border: 1px solid rgb(0, 0, 0);
    padding: 2px 5px 2px 5px;
    background: rgb(200, 200, 233);
}

.not-outlined {
    border: 1px solid rgb(224, 223, 223);
    padding: 2px 5px 2px 5px;
    background: none;
}