.main {
    /* position:relative; */
    /* margin-top:50px; */

}

.main-children {
    /* padding-top: 50px; */
    /* color: navy; */
}

@media print {
    .main {margin-top:0; border: none;}
    .main-children {
        padding-top:0;
        color: #000000;
        
    }
    .center {
        margin: auto;
        width: 100%;
        
   }
              /*Firefox*/

}
