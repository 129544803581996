.container {
   
   border:1px dashed grey;
   /* background: url('../../assets/img/1.png'); */
   background: url('../../assets/img/2017.jpg');        
background-size: 1000px auto;
background-repeat: no-repeat;
background-position: center; 
width:1000px; height: 550px;
background-color: '#ffffff';

}
