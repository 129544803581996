.center {
    margin: auto;
    width: 50%;
    
}

div.rotated {
  writing-mode: tb-rl;
}

body {
    margin: 0;
    background: #edf3f8;
    color: #000000;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    height: 297mm; 
    top:0px;
    left:0px;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact; 
    position:relative;
   
}

div.portrait, div.landscape {
    margin:  auto;
    /* padding: 10mm; */
    border: solid 1px black;
    overflow: hidden;
    page-break-after: always;
    background: white;
}

div.portrait {
    width: 190mm;
    height: 276mm;
    margin: auto;
    
    position: relative;

}

div.landscape {
    width: 276mm;
    height: 190mm;
}

a { text-decoration: none; color: #000000;}

@media print  {
  @page {
       /* margin: 2cm; */
  }
    html, body {
        /* width: 210mm;
        height: 297mm; */
        top:0px;
        left:0px;
        
        position:relative;
      }
    body {
      background: none;
      zoom: 0.99;
      -ms-zoom: 1.665;
    }
    div.portrait, div.landscape {
      margin: 0;
      padding: 0;
      border: none;
    }
    div.landscape {
      transform: rotate(270deg) translate(-276mm, 0);
      transform-origin: 0 0;
    }

    div.no-print { 
      display: none; 
      visibility: hidden; 
    }

    div.iso-envelope { border: none; }
    footer { page-break-after: always; }

  }

  /* @media print and (width: 21cm) and (height: 29.7cm) {
    @page {
       margin: 3cm;
    }
 } */