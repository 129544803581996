.iso-envelope {

border: 3px dotted rgb(192, 204, 255);
}

.btn-default-format {
    margin: 3px;
    padding: 8px 5px 8px 5px;
    background: white;
    color: rgb(35, 12, 73);
    border: 1px solid black;
    min-width: 50px;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
}

.btn-default-format:hover {
    background: rgb(198, 216, 250);
    color: black;
    font-weight: 700;
    
}

.btn-selected-format {
    margin: 3px;
    padding: 10px 2px 10px 2px;
    background: rgb(34, 6, 196);
    color: white;
    border: black;
    font-weight: 600;
    min-width: 50px;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
}

hr { border:0.05em dotted rgb(114, 114, 114)}

.center {
    margin: auto;
    width: 50%;
    position: relative;
}
@media screen {
    .iso-envelope { margin-top:0px;}
}
/* @media print {
    html, body {
        width: 210mm;
        height: 297mm;
        top:10px;
        
        position:relative;
      }
    
    .iso-envelope { border:0px solid black}
}

@media screen {
    
}

@page {
    size: A4;
    margin: 0;
  } */
@media print {
    footer {page-break-after: always;}
}