.toolbar {
    clear: both;
    position:fixed;
    width: 100%;
    top:0;left:0;
    background: rgb(241, 241, 248);
    height: 50px;
    
    z-index: 2;
    /* flex:grow; */
    /* flex-direction: column; */
    /* flex-grow: 1; */
    /* border: 1px solid black; */
}

.v-center {
    margin:0;
    position: relative;
    display: flex;
    width:100%;
    justify-content: space-between;
    top: 50%;

    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

@media print {
    .toolbar { display: none}
}